.toolbar {
  left: 0px;
  top: 0px;

  background: #ffffff;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
}

.toolbar-text {
  height: 10px;
  text-align: center;

  flex: none;
  order: 1;
  flex-grow: 0;
}
