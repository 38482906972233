.green {
  filter: invert(75%) sepia(4%) saturate(5123%) hue-rotate(119deg) brightness(82%) contrast(76%);
}

.footer {
  width: 100%;

  bottom: 0px;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.05);
  background-color: "#ffffff";
  justify-content: space-between;
  position: fixed;
  align-items: center;
}

.MuiCardActionArea-root.navigationItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  min-height: 60px;

  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 6px;
}

.MuiTypography-root.navigationText {
  height: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  margin-top: 10px;

  text-align: center;

  flex: none;
  order: 1;
  flex-grow: 0;
}
